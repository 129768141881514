import React from "react";

import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <div
      style={{ padding: 50, paddingRight: "25%", paddingLeft: "25%" }}
      class="c25 doc-content"
    >
      <p class="c3">
        <span class="c8">PRIVACY POLICY</span>
      </p>
      <p class="c21">
        <span class="c4">
          We value the trust you place in us and recognize the importance of
          secure transactions and information privacy. This Privacy Policy
          describes how qualido Internet Private Limited and its affiliates
          (collectively &ldquo;Qualido, we, our, us &rdquo;) collect, use,
          share, or otherwise process your personal information through Qualido
          website
        </span>
        <span class="c6">
          <a class="c9" href="https://www.qualido.in">
            &nbsp;www.qualido.in
          </a>
        </span>
        <span class="c4">
          , its mobile application, and m-site (hereinafter referred as the
          &ldquo;Platform &rdquo;).
        </span>
      </p>
      <p class="c11">
        <span class="c4">
          While you may be able to browse certain sections of the Platform
          without registering with us, however, please note we do not offer any
          product or service under this Platform outside India. Your personal
          information will primarily be stored and processed in India and may
          have data protection laws that are different from those that apply in
          the country in which you are located. By visiting this Platform,
          providing your information or availing out product/service, you
          expressly agree to be bound by the terms and conditions of this
          Privacy Policy, the
        </span>
        <span class="c6">
          <a class="c9" href="https://qualido.in/terms-and-conditions">
            &nbsp;Terms of Use
          </a>
        </span>
        <span class="c0">
          &nbsp;and the applicable service/product terms and conditions, and
          agree to be governed by the laws of India including but not limited to
          the laws applicable to data protection and privacy. If you do not
          agree please do not use or access our Platform.
        </span>
      </p>
      <p class="c21">
        <span class="c10">Collection of Your Information</span>
      </p>
      <p class="c7">
        <span class="c0">
          When you use our Platform, we collect and store your information which
          is provided by you from time to time. In general, you can browse the
          Platform without telling us who you are or revealing any personal
          information about yourself. Once you give us your personal
          information, you are not anonymous to us. Where possible, we indicate
          which fields are required and which fields are optional. You always
          have the option to not provide information by choosing not to use a
          particular service, product or feature on the Platform.
        </span>
      </p>
      <p class="c18">
        <span class="c4">
          We may track your buying behaviour, preferences, and other information
          that you choose to provide on our Platform. We use this information to
          do internal research on our users &#39;demographics, interests, and
          behaviour to better understand, protect and serve our users. This
          information is compiled and analysed on an aggregated basis. This
          information may include the URL that you just came from (whether this
          URL is on our Platform or not), which URL you next go to (whether this
          URL is on our Platform or not), your computer browser information, and
          your IP address.
        </span>
      </p>
      <p class="c26">
        <span class="c4">
          We may collect personal information (such as email address, delivery
          address, name, phone number, credit card/debit card and other payment
          instrument details) from you when you set up an account or transact
          with us or participate in any event or contest. While you can browse
          some sections of our Platform without being a registered member,
          certain activities (such as placing an order or consuming our online
          content or services) do require registration. We use your contact
          information to send you offers based on your previous orders and your
          interests.
        </span>
      </p>
      <p class="c32">
        <span class="c2">&nbsp;</span>
      </p>
      <p class="c15">
        <span class="c0">
          If you choose to post messages on our message boards, chat rooms or
          other message areas or leave feedback on the Platform or the social
          media handles maintained by us or if you use voice commands or virtual
          try and buy or similar feature to shop on the Platform, we will
          collect that information you provide to us. We retain this information
          as necessary to resolve disputes, provide customer support,
          troubleshoot problems or for internal research and analysis as
          permitted by law.
        </span>
      </p>
      <p class="c18">
        <span class="c2">&nbsp;</span>
      </p>
      <p class="c38">
        <span class="c0">
          If you send us personal correspondence, such as emails or letters, or
          if other users or third parties send us correspondence about your
          activities or postings on the Platform, we may collect such
          information into a file specific to you.
        </span>
      </p>
      <p class="c37">
        <span class="c2">&nbsp;</span>
      </p>
      <p class="c20">
        <span class="c0">
          If you enrol into our SuperCoins Loyalty Program or similar programs
          offered by Qualido, we will collect and store your personal
          information such as name, contact number, email address, communication
          address, date of birth, gender, zip code, lifestyle information,
          demographic and work details which is provided by you to Qualido or a
          third-party business partner that operates online/offline
          establishments or platforms where you can earn SuperCoins for purchase
          of
        </span>
      </p>
      <p class="c13">
        <span class="c0"></span>
      </p>
      <p class="c11">
        <span class="c0">
          goods and services, and redeem SuperCoins. We will also collect your
          information related to your transactions on the Qualido platform and
          such third-party business partner platforms. When such a third-party
          business partner collects your personal information directly from you,
          you will be governed by their privacy policies. Qualido shall not be
          responsible for the third-party business partner &rsquo;s privacy
          practices or the content of their privacy policies, and we request you
          to read their privacy policies prior to disclosing any information
        </span>
      </p>
      <p class="c11 c16">
        <span class="c0"></span>
      </p>
      <p class="c7">
        <span class="c4">
          Qualido has onboarded certain third-party business partners on the
          Platform who specialise in the categories like travel ticket
          reservations, booking online movie tickets, paying online bills and
          more (Ultra-Partners). If you use the services of Ultra-Partners, you
          will be redirected to Ultra-Partners websites/ applications and your
          entry to Ultra-Partners websites/applications will be based on your
          Qualido login credentials after seeking your permissions to share the
          data further. Qualido shall not be responsible for the Ultra-Partner
          &rsquo;s privacy practices or the content of their privacy policies,
          and we request you to read their privacy policies prior to disclosing
          any information.
        </span>
      </p>
      <p class="c5">
        <span class="c10">
          Use of Demographic / Profile Data / Your Information
        </span>
      </p>
      <p class="c11">
        <span class="c4">
          We use your personal information to provide the product and services
          you request. To the extent we use your personal information to market
          to you, we will provide you the ability to opt-out of such uses. We
          use your personal information to assist sellers and business partners
          in handling and fulfilling orders; enhancing customer experience;
          resolve disputes; troubleshoot problems; help promote a safe service;
          collect money; measure consumer interest in our products and services;
          inform you about online and offline offers, products, services, and
          updates; customize and enhance your experience; detect and protect us
          against error, fraud and other criminal activity; enforce our terms
          and conditions; and as otherwise described to you at the time of
          collection of information.
        </span>
      </p>
      <p class="c1">
        <span class="c2">&nbsp;</span>
      </p>
      <p class="c26">
        <span class="c4">
          With your consent, we may have access to your SMS, instant messages,
          contacts in your directory, location, camera, photo gallery and device
          information. We may also request you to provide your PAN, GST Number,
          Government issued ID cards/number and Know-Your-Customer (KYC) details
          to: (i) check your eligibility for certain products and services
          including but not limited to credit and payment products; (ii) issue
          GST invoice for the products and services purchased for your business
          requirements; (iii) enhance your experience on the Platform and
          provide you access to the products and services being offered by us,
          sellers, affiliates or lending partners. You understand that your
          access to these products/services may be affected in the event consent
          is not provided to us.
        </span>
      </p>
      <p class="c5">
        <span class="c4">
          In our efforts to continually improve our product and service
          offerings, we and our affiliates collect and analyse demographic and
          profile data about our users &#39;activity on our Platform. We
          identify and use your IP address to help diagnose problems with our
          server, and to administer our Platform. Your IP address is also used
          to help identify you and to gather broad demographic information.
        </span>
      </p>
      <p class="c20">
        <span class="c4">
          We will occasionally ask you to participate in optional surveys
          conducted either by us or through a third-party market research
          agency. These surveys may ask you for personal information, contact
          information, date of birth, demographic information (like zip code,
          age, or income level), attributes such as your interests, household or
          lifestyle information, your purchasing behaviour or history,
          preferences, and other such information that you may choose to
          provide. The surveys may involve collection of voice data or video
          recordings, the participation of which would purely be voluntary in
          nature. We use this data to tailor your experience at our Platform,
          providing you with content that we think you might be interested in
          and to display content according to your preferences.
        </span>
      </p>
      <p class="c1">
        <span class="c2">&nbsp;</span>
      </p>
      <p class="c5">
        <span class="c10">Cookies</span>
      </p>
      <p class="c36">
        <span class="c4">
          We use data collection devices such as &quot;cookies &quot;on certain
          pages of the Platform to help analyse our web page flow, measure
          promotional effectiveness, and promote trust and safety. &quot;Cookies
          &quot;are small files placed on your hard drive that assist us in
          providing our services. Cookies do not contain any of your personal
          information. We offer certain features that are only available through
          the use of a &quot;cookie &quot;. We also use cookies to allow you to
          enter your password less frequently during a session. Cookies can also
          help us provide information that is targeted to your interests. Most
          cookies are &quot;session cookies,&quot;meaning that they are
          automatically deleted from your hard drive at the end of a session.
          You are always free to decline/delete our cookies if your browser
          permits, although in that case you may not be able to use certain
          features on the Platform and you may be required to re-enter your
          password more frequently during a session. Additionally, you may
          encounter &quot;cookies &quot;or other similar devices on certain
          pages of the Platform that are placed by third parties. We do not
          control the use of cookies by third parties. We use cookies from
          third-party partners such as Google Analytics for marketing and
          analytical purposes. Google Analytics help us understand how our
          customers use the site. You can read more about how Google uses your
          personal information here:
        </span>
        <span class="c6">
          <a
            class="c9"
            href="https://www.google.com/url?q=https://www.google.com/intl/en/policies/privacy/&amp;sa=D&amp;source=editors&amp;ust=1673288195269769&amp;usg=AOvVaw1R-I22jNDfUwp-vxQpO5KS"
          >
            &nbsp;https://www.google.com/intl/en/policies/privacy/
          </a>
        </span>
        <span class="c4">. You can opt-out of</span>
        <span class="c30">&nbsp;</span>
        <span class="c4">Google Analytics here:</span>
        <span class="c6">
          <a
            class="c9"
            href="https://www.google.com/url?q=https://tools.google.com/dlpage/gaoptout&amp;sa=D&amp;source=editors&amp;ust=1673288195270289&amp;usg=AOvVaw1ymeePSL77r-z_KVcKrSFP"
          >
            &nbsp;https://tools.google.com/dlpage/gaoptout
          </a>
        </span>
        <span class="c4">
          . You can also control the use of cookies at the individual browser
          level, but if you choose to disable cookies, it may limit your use of
          certain features or functions on the services.
        </span>
      </p>
      <p class="c5">
        <span class="c10">Sharing of personal information</span>
      </p>
      <p class="c31">
        <span class="c4">
          We may share personal information internally within Qualido group
          companies, affiliates, related companies and with other third parties
          for purposes of providing products and services offered by them.
        </span>
      </p>
      <p class="c11">
        <span class="c0">
          We may disclose your personal information to third parties, such as
          sellers, business partners. This disclosure may be required for us to
          provide you access to our products and services; for fulfilment of
          your orders; for enhancing your experience; for providing feedback on
          products; to collect payments from you; to comply with our legal
          obligations; to conduct market research or surveys; to enforce our
          Terms of Use; to facilitate our marketing and advertising activities;
          to analyse data; for customer service assistance; to prevent, detect,
          mitigate, and investigate fraudulent or illegal activities related to
          our product and services. We do not disclose your personal information
          to third parties for their marketing and advertising purposes without
          your explicit consent.
        </span>
      </p>
      <p class="c11 c16">
        <span class="c0"></span>
      </p>
      <p class="c20">
        <span class="c0">
          We may disclose personal information if required to do so by law or in
          the good faith belief that such disclosure is reasonably necessary to
          respond to subpoenas, court orders, or other legal process. We may
          disclose personal information to law enforcement agencies, third party
          rights owners, or others in the good faith belief that such disclosure
          is reasonably necessary to: enforce our Terms of Use or Privacy
          Policy; respond to claims that an advertisement, posting or other
          content violates the rights of a third party; or protect the rights,
          property or personal safety of our users or the general public.
        </span>
      </p>
      <p class="c20 c16">
        <span class="c0"></span>
      </p>
      <p class="c11">
        <span class="c4">
          We and our affiliates will share / sell some or all of your personal
          information with another business entity should we (or our assets)
          plan to merge with, or be acquired by that business entity, or
          re-organization, amalgamation, restructuring of business. Should such
          a transaction occur that other business entity (or the new combined
          entity) will be required to follow this Privacy Policy with respect to
          your personal information.
        </span>
      </p>
      <p class="c5">
        <span class="c10">Links to Other Sites</span>
      </p>
      <p class="c27">
        <span class="c4">
          Our Platform may provide links to other websites or application that
          may collect personal information about you. We are not responsible for
          the privacy practices or the content of those linked websites.
        </span>
      </p>
      <p class="c5">
        <span class="c10">Security Precautions</span>
      </p>
      <p class="c20">
        <span class="c0">
          We maintain reasonable physical, electronic and procedural safeguards
          to protect your information. Whenever you access your account
          information, we offer the use of a secure server. Once your
          information is in our possession, we adhere to our security guidelines
          to protect it against unauthorized access. However, by using the
          Platform, the users accept the inherent security implications of data
          transmission over the internet and the World Wide Web which cannot
          always be guaranteed as completely secure, and therefore, there would
          always remain certain inherent risks regarding use of the Platform.
          Users are responsible for ensuring the protection of login and
          password records for their account.
        </span>
      </p>
      <p class="c13">
        <span class="c0"></span>
      </p>
      <p class="c5">
        <span class="c10">Choice/Opt-Out</span>
      </p>
      <p class="c19">
        <span class="c4">
          We provide all users with the opportunity to opt-out of receiving
          non-essential (promotional, marketing-related) communications after
          setting up an account with us. If you do not wish to receive
          promotional communications from us, then please login into the
          Notification Preference page of Platform
          [https://www.qualido.in/communication-preferences/email] to
          unsubscribe/opt-out.
        </span>
      </p>
      <p class="c5">
        <span class="c10">Advertisements on Platform</span>
      </p>
      <p class="c26">
        <span class="c0">
          We use third-party advertising companies to serve ads when you visit
          our Platform. These companies may use information (not including your
          name, address, email address, or telephone number) about your visits
          to this and other websites in order to provide advertisements about
          goods and services of interest to you.You have an option to opt out
          from tracking of personalized advertising using the &ldquo;Opt out of
          Ads Personalization &rdquo;settings using your device &rsquo;s
          settings application. Qualido will have no access to your GAID once
          you select this feature.
        </span>
      </p>
      <p class="c17">
        <span class="c2">&nbsp;</span>
      </p>
      <p class="c5">
        <span class="c10">Children &#39;s</span>
        <span class="c10">&nbsp;Information</span>
      </p>
      <p class="c7">
        <span class="c4">
          Use of our Platform is available only to persons who can form a
          legally binding contract under the Indian Contract Act, 1872. We do
          not knowingly solicit or collect personal information from children
          under the age of 18 years. If you have shared any personal information
          of children under the age of 18 years, you represent that you have the
          authority to do so and permit us to use the information in accordance
          with this Privacy Policy.
        </span>
      </p>
      <p class="c14">
        <span class="c2">&nbsp;</span>
      </p>
      <p class="c5">
        <span class="c10">Data Retention</span>
      </p>
      <p class="c28">
        <span class="c0">
          We retain your personal information in accordance with appliable laws,
          for a period no longer than is required for the purpose for which it
          was collected or as required under any applicable law. However, we may
          retain data related to you if we believe it may be necessary to
          prevent fraud or future abuse, to enable Qualido to exercise its legal
          rights and/or defend against legal claims or if required by law or for
          other legitimate purposes. We may continue to retain your data in
          anonymised form for analytical and research purposes. Your Rights
        </span>
      </p>
      <p class="c21">
        <span class="c0">
          We take every reasonable step to ensure that your personal information
          that we process is accurate and, where necessary, kept up to date, and
          any of your personal information that we process that you inform us is
          inaccurate (having regard to the purposes for which they are
          processed) is erased or rectified. You may access, correct, and update
          your personal information directly through the functionalities
          provided on the Platform. You may delete certain non-mandatory
          information by logging into our website and visiting Profile and
          Settings sections. You can also write to us at the contact information
          provided below to assist you with these requests.
        </span>
      </p>
      <p class="c35">
        <span class="c2">&nbsp;</span>
      </p>
      <p class="c20">
        <span class="c4">
          You have an option to withdraw your consent that you have already
          provided by writing to us at the contact information provided below.
          Please mention &ldquo;for withdrawal of consent &rdquo;in the subject
          line of your communication. We will verify such requests before acting
          upon your request. Please note, however, that withdrawal of consent
          will not be retroactive and will be in accordance with the terms of
          this Privacy Policy, related Terms of Use and applicable laws. In the
          event you withdraw consent given to us under this Privacy Policy, such
          withdrawal may hamper your access to the Platform or restrict
          provision of our services to you for which we consider that
          information to be necessary.
        </span>
      </p>
      <p class="c5">
        <span class="c10">Your Consent</span>
      </p>
      <p class="c20">
        <span class="c0">
          By visiting our Platform or by providing your information, you consent
          to the collection, use, storage, disclosure and otherwise processing
          of your information (including sensitive personal information) on the
          Platform in accordance with this Privacy Policy. If you disclose to us
          any personal information relating to other people, you represent that
          you have the authority to do so and to permit us to use the
          information in accordance with this Privacy Policy.
        </span>
      </p>
      <p class="c16 c20">
        <span class="c0"></span>
      </p>
      <p class="c33">
        <span class="c0">
          You, while providing your personal information over the Platform or
          any partner platforms or establishments, consent to us (including our
          other corporate entities, affiliates, lending partners, technology
          partners, marketing channels, business partners and other third
          parties) to contact you through SMS, instant messaging apps, call
          and/or e-mail for the purposes specified in this Privacy Policy.
        </span>
      </p>
      <p class="c21">
        <span class="c2">&nbsp;</span>
      </p>
      <p class="c5">
        <span class="c10 c24">Changes to this Privacy Policy</span>
      </p>
      <p class="c22">
        <span class="c0">
          Please check our Privacy Policy periodically for changes. We may
          update this Privacy Policy to reflect changes to our information
          practices. We will alert you to significant changes by posting the
          date our policy got last updated, placing a notice on our Platform, or
          by sending you an email when we are required to do so by applicable
          law.
        </span>
      </p>
      <p class="c16 c23">
        <span class="c24 c34"></span>
      </p>
    </div>
  );
};

export default PrivacyPolicy;
