const reviews = [
  {
    name: "Abdullah",
    location: "Delhi",
    image:
      "https://drive.google.com/file/d/1nx_9Z15r0yfZy7NxWmjUpXSfWxy9odE9/view?usp=share_link",
    rating: 5,
    review:
      "Thank  you for such fast and amazing delivery! it was really a great deal, the bookmark and the diary was amazing , Nice job & all the best qualido",
  },
  {
    name: "Sayan",
    location: "West Bengal",
    image: "",
    rating: 5,
    review:
      "I got this bunch of books in cheapest price. Thank you qualido for providing this to my doorstep",
  },
  {
    name: "Veer Agarwal",
    location: "Kanpur",
    image:
      "https://drive.google.com/file/d/1w9ao06YTW0jfHaGeYOks_5-JCXkn4fZa/view?usp=share_link",
    rating: 4.5,
    review:
      "I was searching for Gate study material and found qualido , they are delivering all types of books. I purchased and it was the best deal. Thank you qualido",
  },
  {
    name: "Ali",
    location: "Sambhal",
    image:
      "https://drive.google.com/file/d/1V90EdeLskNVCsmNKRD2hfnLcAHBMBdoj/view?usp=share_link",
    rating: 4.5,
    review:
      "Searching for best deal and suddenly, I found qualido which was providing at best price by saying quality at your door, So i just ordered and found the best thing out of it.",
  },
  {
    name: "Bharthi",
    location: "Karnataka",
    image:
      "https://drive.google.com/file/d/1byS2hTdyjdW90SER_zpEoIn66OTyhmjT/view?usp=share_link",
    rating: 4.9,
    review:
      "Love the book quality, packaging and specially the customized book marks that came along .Thank you qualido ",
  },
];

export default reviews;
